import { Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'
import MessagesUtils from '../../utils/messagesUtils'

export const DefaultCategory = Record({
  payload: {},
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class Category extends DefaultCategory {
  getCategoryRequest() {
    //MessagesUtils.showLoadingBox('Loading!')
    return StateUtils.createRequestedRecordState(new Category())
  }

  getCategorySuccess(payload) {
    try {
      MessagesUtils.showSuccessMessage('Successfully category gotten!')
      if (!payload || payload.length === 0) {
      }
      const category = StateUtils.createSuccessRecordState(new Category())
      return category.set('payload', payload.data)
    } catch (err) {
    }
  }

  getCategoryFailure(payload) {
    const status = payload.response.status
    const { message, errors } = payload.response.data
    MessagesUtils.showErrorMessage(message, status, errors)
    return StateUtils.createFailureRecordState(new Category())
  }
}
