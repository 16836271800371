import { Record } from 'immutable'

export const DefaultLayoutState = Record({
  component: 'Dashboard',
  completed: false,
})

export default class LayoutState extends DefaultLayoutState {
  setLayoutState(payload) {
    return new LayoutState(payload)
  }
}
