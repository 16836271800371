import { combineReducers } from 'redux'
import User from './models/user'
import Users from './models/users'
import Statistics from './models/statistics'
import Enrollments from './models/enrollments'
import LayoutState from './models/layout'
import reducer from './reducer'
import Categories from './models/categories'
import Category from './models/category'
import UpdateCategory from './models/update-category'
import Advertisement, { AdvertisementBase } from './models/advertisement'
import Advertisements from './models/advertisements'
import ClientAdvertisements from './models/client-advertisements'
import Sidebar from './models/sidebar'
import UpdateAdvertisement from './models/update-advertisement'
import UpdateStatistics from './models/update-statictics'
import UserStatistics from './models/user-statistics'
import UserEnrollments from './models/user-enrollments'
import PaymentRequests from './models/payment-requests'
import UserImages from './models/user-images'
import Coefficients from './models/coefficients'

const rootReducer = combineReducers({
  user: reducer(new User()),
  users: reducer(new Users()),
  categories: reducer(new Categories()),
  category: reducer(new Category()),
  layoutState: reducer(new LayoutState()),
  updateCategory: reducer(new UpdateCategory()),
  sidebar: reducer(new Sidebar()),
  advertisement: reducer(new Advertisement()),
  advertisementBase: reducer(new AdvertisementBase()),
  advertisements: reducer(new Advertisements()),
  updateAdvertisement: reducer(new UpdateAdvertisement()),
  statistics: reducer(new Statistics()),
  enrollments: reducer(new Enrollments()),
  updateStatistics: reducer(new UpdateStatistics()),
  userEnrollments: reducer(new UserEnrollments()),
  userStatistics: reducer(new UserStatistics()),
  paymentRequests: reducer(new PaymentRequests()),
  clientAdvertisements: reducer(new ClientAdvertisements()),
  userImages: reducer(new UserImages()),
  coefficients: reducer(new Coefficients()),
})

export default rootReducer
