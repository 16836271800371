import { Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'

export const DefaultCoefficients = Record({
  payload: {},
  oldPayload: {},
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class Coefficients extends DefaultCoefficients {
  getCoefficientsRequest() {
    return StateUtils.createRequestedRecordState(new Coefficients())
  }

  getCoefficientsSuccess(payload) {
    try {
      if (!payload || payload.length === 0) {
      }
      const coefficients = StateUtils.createSuccessRecordState(new Coefficients())
      return coefficients.set('payload', payload.data)
    } catch (err) {}
  }

  getCoefficientsFailure(payload) {
    return StateUtils.createFailureRecordState(new Coefficients())
  }
}
