import { List, Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'

export const DefaultUsers = Record({
  payload: {},
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class Users extends DefaultUsers {
  getUsersRequest() {
    return StateUtils.createRequestedRecordState(new Users())
  }

  getUsersSuccess(payload) {
    try {
      if (!payload || payload.length === 0) {
      }
      const users = StateUtils.createSuccessRecordState(new Users())
      return users.set('payload', payload.data)
    } catch (err) {
      console.log('error: ', err)
    }
  }

  getUsersFailure(payload) {
    return StateUtils.createFailureRecordState(new Users())
  }
}
