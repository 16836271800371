import { Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'

export const DefaultAdvertisements = Record({
  payload: {},
  oldPayload: {},
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class Advertisements extends DefaultAdvertisements {
  getAdvertisementsRequest() {
    return StateUtils.createRequestedRecordState(new Advertisements())
  }

  getAdvertisementsSuccess(payload) {
    try {
      if (!payload || payload.length === 0) {
      }
      const advertisements = StateUtils.createSuccessRecordState(new Advertisements())
      return advertisements.set('payload', payload.data)
    } catch (err) {
    }
  }

  getAdvertisementsFailure(payload) {
    return StateUtils.createFailureRecordState(new Advertisements())
  }
}
