import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Popup from 'react-popup'

export default class MessagesUtils {
  static removeAllMessages() {
    toast.dismiss()
  }

  static showSuccessMessage(message) {
    toast.success(message, { position: toast.POSITION.TOP_RIGHT })
  }

  static showWarningMessage(message) {
    toast.warning(message, { position: toast.POSITION.TOP_RIGHT })
  }

  static showErrorMessage(message, statusCode, detailedErrorMessage = '') {
    const CustomErr = () => (
      <div>
        <p className='text'>Message: {message}</p>
        <p className='text'>StatusCode: {statusCode}</p>
        <p className='text'>Detailed Error: {detailedErrorMessage}</p>
      </div>
    )

    toast.error(
      <CustomErr
        text={message}
        statusCode={statusCode}
        detailedErrorMessage={detailedErrorMessage}
      />,
    )
  }

  static showModal(title, message, successMessage, closeFunc, saveFunc) {
    return Popup.create({
      title: title,
      content: message,
      buttons: {
        left: [
          {
            text: 'Cancel',
            className: 'danger',
            action: function () {
              if (closeFunc != undefined) {
                closeFunc()
              }

              Popup.close()
            },
          },
        ],
        right: [
          {
            text: 'Save',
            className: 'success',
            action: function () {
              if (saveFunc != undefined) {
                saveFunc()
              }

              toast.success(successMessage, { position: toast.POSITION.TOP_RIGHT })
              Popup.close()
            },
          },
        ],
      },
    })
  }

  static showPaymentCompletedModal(title, message) {
    return Popup.create({
      title: title,
      content: message,
      buttons: {},
    })
  }

  static showLoadingBox(message) {}

  static removeLoadingBox() {}
}
