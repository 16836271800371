import { Record } from 'immutable'

export const DefaultUpdateAdvertisement = Record({
  id: '',
})

export default class UpdateAdvertisement extends DefaultUpdateAdvertisement {
  setUpdateAdvertisement(value) {
    return this.set('id', value)
  }
}
