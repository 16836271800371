import axios from 'axios'
import * as c from '../api/Constants'

console.log(c.API_URL)

const instance = axios.create({
  baseURL: c.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default instance
