import { Record } from 'immutable'

export const DefaultAdvertisement = Record({
  payload: {},
})

export default class Advertisement extends DefaultAdvertisement {
  setAdvertisement(payload) {
    return this.set('payload', payload)
  }
}

export class AdvertisementBase extends DefaultAdvertisement {
  setAdvertisementBase(payload) {
    return this.set('payload', payload)
  }
}
