import { Record } from 'immutable'

export const DefaultSidebar = Record({
  payload: {
    sidebarUnfoldable: false,
    sidebarShow: true,
  },
})

export default class Sidebar extends DefaultSidebar {
  setSidebar(payload) {
    return this.set('payload', payload)
  }
}
