import { Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'

export const DefaultPaymentRequests = Record({
  payload: {},
  oldPayload: {},
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class PaymentRequests extends DefaultPaymentRequests {
  getPaymentRequestsRequest() {
    return StateUtils.createRequestedRecordState(new PaymentRequests())
  }

  getPaymentRequestsSuccess(payload) {
    try {
      if (!payload || payload.length === 0) {
      }
      const paymentRequests = StateUtils.createSuccessRecordState(new PaymentRequests())
      return paymentRequests.set('payload', payload.data)
    } catch (err) {}
  }

  getPaymentRequestsFailure(payload) {
    return StateUtils.createFailureRecordState(new PaymentRequests())
  }
}
