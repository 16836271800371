import { Record } from 'immutable'

export const DefaultUpdateStatistics = Record({
  userId: '',
  advertisementId: '',
  clientId: '',
})

export default class UpdateStatistics extends DefaultUpdateStatistics {
  setUpdateStatistics(value) {
    return this.set('userId', value.userId)
      .set('advertisementId', value.advertisementId)
      .set('clientId', value.clientId)
  }
}
