import { Record } from 'immutable'

export const DefaultUpdateCategory = Record({
  id: -1,
})

export default class UpdateCategory extends DefaultUpdateCategory {
  setUpdateCategory(value) {
    return this.set('id', value)
  }
}
