import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import TokenService from './services/token.service'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./containers/core-layout/CoreLayout'))

// Pages
const Login = React.lazy(() => import('./containers/pages/login/Login'))
const Register = React.lazy(() => import('./containers/pages/register/Register'))
const Page404 = React.lazy(() => import('./containers/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./containers/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />

            {/*<Route path="*" name="Home" element={<DefaultLayout />} />*/}
            <Route
              path="/:paymentCompleted"
              name="Home"
              element={
                TokenService.notEmpty() ? (
                  <DefaultLayout {...this.props} />
                ) : (
                  <Login {...this.props} />
                )
              }
            />
            <Route
              path="/"
              name="Home"
              element={
                TokenService.notEmpty() ? (
                  <DefaultLayout {...this.props} />
                ) : (
                  <Login {...this.props} />
                )
              }
            />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
