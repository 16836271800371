import { Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'
import MessagesUtils from '../../utils/messagesUtils'

export const DefaultCategories = Record({
  payload: {},
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class Categories extends DefaultCategories {
  getCategoriesRequest() {
    //MessagesUtils.showLoadingBox('Loading!')
    return StateUtils.createRequestedRecordState(new Categories())
  }

  getCategoriesSuccess(payload) {
    try {
      MessagesUtils.showSuccessMessage('Successfully categories gotten!')
      if (!payload || payload.length === 0) {
      }
      const categories = StateUtils.createSuccessRecordState(new Categories())
      return categories.set('payload', payload.data)
    } catch (err) {
      console.log('error: ', err)
    }
  }

  getCategoriesFailure(payload) {
    const status = payload.response.status
    const { message, errors } = payload.response.data
    MessagesUtils.showErrorMessage(message, status, errors)
    return StateUtils.createFailureRecordState(new Categories())
  }
}
