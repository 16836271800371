import axiosInstance from './api'
import TokenService from './token.service'

const setup = (store) => {
  let retryCount = 0
  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.url.indexOf('auth') > -1) return config
      const token = TokenService.getLocalAccessToken()
      if (token) {
        // config.headers['Authorization'] = 'Bearer ' + token;  // for Spring Boot back-end
        config.headers['authorization'] = token // for Node.js Express back-end
        config.headers['x-correlation-id'] = generate_uuidv4() // for Node.js Express back-end
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  const { dispatch } = store
  axiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config
      if (originalConfig.url !== '/auth/login' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          retryCount = retryCount + 1
          originalConfig._retry = true

          if (err.response.data.message === 'Invalid_Refresh_Token' && retryCount == 5) {
            TokenService.removeUser()
            window.location.href = '/'
          }

          try {
            const rs = await axiosInstance.post('/auth/refresh', {
              refreshToken: TokenService.getLocalRefreshToken(),
            })

            const { accessToken } = rs.data.token

            //dispatch(refreshToken(accessToken))
            TokenService.updateLocalAccessToken(accessToken)

            return axiosInstance(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    },
  )
}

function generate_uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const uuid = (Math.random() * 16) | 0,
      v = c == 'x' ? uuid : (uuid & 0x3) | 0x8
    return uuid.toString(16)
  })
}

export default setup
