import { Record } from 'immutable'

const DefaultRestState = Record({
  meta: Record({
    requested: false,
    ready: false,
    error: false,
  })(),
})

class RestState extends DefaultRestState {
  setState(state) {
    return this.setIn(['meta', 'requested'], state.meta.requested)
      .setIn(['meta', 'ready'], state.meta.ready)
      .setIn(['meta', 'error'], state.meta.error)
  }
}

const InitialState = {
  meta: {
    requested: false,
    ready: false,
    error: false,
  },
}

const RequestedState = {
  meta: {
    requested: true,
    ready: false,
    error: false,
  },
}

const SuccessState = {
  meta: {
    requested: false,
    ready: true,
    error: false,
  },
}

const FailureState = {
  meta: {
    requested: false,
    ready: false,
    error: true,
  },
}

const InitialRecordState = new RestState().setState(InitialState)

const RequestedRecordState = new RestState().setState(RequestedState)

const SuccessRecordState = new RestState().setState(SuccessState)

const FailureRecordState = new RestState().setState(FailureState)

export function createStateWithoutPayload(payload = {}, meta = {}) {
  return {
    ...payload,
    meta: {
      requested: false,
      ready: false,
      error: false,
      ...meta,
    },
  }
}

export function createState(payload = {}, meta = {}) {
  return {
    payload,
    meta: {
      requested: false,
      ready: false,
      error: false,
      ...meta,
    },
  }
}

export function createInitialState(payload) {
  const initial = InitialState
  return createState(payload, initial.meta)
}

export function createInitialStateWithoutPayload(payload) {
  const initial = InitialState
  return createStateWithoutPayload(payload, initial.meta)
}

export function createRequestedState(payload = {}) {
  const requested = RequestedState
  return createState(payload, requested.meta)
}

export function createSuccessState(payload = {}) {
  const success = SuccessState
  return createState(payload, success.meta)
}

export function createFailureState(payload = {}) {
  const failure = FailureState
  return createState(payload, failure.meta)
}

export function createInitialRecordState(state) {
  return state.merge(InitialRecordState)
}

export function createRequestedRecordState(state) {
  return state.merge(RequestedRecordState)
}

export function createSuccessRecordState(state) {
  return state.merge(SuccessRecordState)
}

export function createFailureRecordState(state) {
  return state.merge(FailureRecordState)
}

function hasObjectState(obj) {
  return (
    obj != null &&
    obj.meta != null &&
    obj.meta.ready != null &&
    obj.meta.requested != null &&
    obj.meta.error != null
  )
}

function checkState(state, controlState) {
  return (
    hasObjectState(state) &&
    state.meta.requested === controlState.meta.requested &&
    state.meta.ready === controlState.meta.ready &&
    state.meta.error === controlState.meta.error
  )
}

export function isInitialState(obj) {
  const initial = InitialState
  return checkState(obj, initial)
}

export function isRequestedState(obj) {
  const requested = RequestedState
  return checkState(obj, requested)
}

export function isSuccessState(obj) {
  const success = SuccessState
  return checkState(obj, success)
}

export function isFailureState(obj) {
  const failure = FailureState
  return checkState(obj, failure)
}
