import { List, Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'

export const DefaultUserImages = Record({
  payload: [],
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class UserImages extends DefaultUserImages {
  getUserImagesRequest() {
    return StateUtils.createRequestedRecordState(new UserImages())
  }

  getUserImagesSuccess(payload) {
    try {
      if (!payload || payload.length === 0) {
      }
      const userImages = StateUtils.createSuccessRecordState(new UserImages())
      return userImages.set('payload', payload.data)
    } catch (err) {
      console.log('error: ', err)
    }
  }

  getUserImagesFailure(payload) {
    return StateUtils.createFailureRecordState(new UserImages())
  }
}
