import { List, Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'

export const DefaultStatistics = Record({
  payload: {
    totalUser: 0,
    clients: 0,
    users: 0,
    advertisements: 0,
    income: 0,
    categories: 0,
    distants: 0,
    durations: 0,
    enrollmentProgress: [],
    gendersCounts: [],
    ageCounts: [],
    user: {},
    advertisement: {},
  },
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class Statistics extends DefaultStatistics {
  getStatisticsRequest() {
    return StateUtils.createRequestedRecordState(new Statistics())
  }

  getStatisticsSuccess(payload) {
    try {
      if (!payload || payload.length === 0) {
      }
      const users = StateUtils.createSuccessRecordState(new Statistics())
      return users.set('payload', payload.data)
    } catch (err) {}
  }

  getStatisticsFailure(payload) {
    return StateUtils.createFailureRecordState(new Statistics())
  }
}
