import { Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'

export const DefaultClientAdvertisements = Record({
  payload: {},
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class ClientAdvertisements extends DefaultClientAdvertisements {
  getClientAdvertisementsRequest() {
    return StateUtils.createRequestedRecordState(new ClientAdvertisements())
  }

  getClientAdvertisementsSuccess(payload) {
    try {
      if (!payload || payload.length === 0) {
      }
      const clientAdvertisements = StateUtils.createSuccessRecordState(new ClientAdvertisements())
      return clientAdvertisements.set('payload', payload.data)
    } catch (err) {
    }
  }

  getClientAdvertisementsFailure(payload) {
    return StateUtils.createFailureRecordState(new ClientAdvertisements())
  }
}
