import { List, Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'

export const DefaultUserStatistics = Record({
  payload: {
    enrollmentProgress: [],
    gendersCounts: [],
    ageCounts: [],
  },
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class UserStatistics extends DefaultUserStatistics {
  getUserStatisticsRequest() {
    return StateUtils.createRequestedRecordState(new UserStatistics())
  }

  getUserStatisticsSuccess(payload) {
    try {
      if (!payload || payload.length === 0) {
      }
      const users = StateUtils.createSuccessRecordState(new UserStatistics())
      return users.set('payload', payload.data)
    } catch (err) {
      console.log('error: ', err)
    }
  }

  getUserStatisticsFailure(payload) {
    return StateUtils.createFailureRecordState(new UserStatistics())
  }
}
