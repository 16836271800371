import { List, Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'

export const DefaultEnrollments = Record({
  payload: {
    data: [],
    meta: {},
  },
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class Enrollments extends DefaultEnrollments {
  getEnrollmentsRequest() {
    return StateUtils.createRequestedRecordState(new Enrollments())
  }

  getEnrollmentsSuccess(payload) {
    try {
      if (!payload || payload.length === 0) {
      }
      const users = StateUtils.createSuccessRecordState(new Enrollments())
      return users.set('payload', payload.data)
    } catch (err) {}
  }

  getEnrollmentsFailure(payload) {
    return StateUtils.createFailureRecordState(new Enrollments())
  }
}
