class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem('user'))
    return user?.token.refreshToken
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem('user'))
    return user?.token.accessToken
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem('user'))
    user.token.accessToken = token
    localStorage.setItem('user', JSON.stringify(user))
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  setUser(u) {
    const user = {
      email: u.user.email,
      token: u.token,
    }
    localStorage.setItem('user', JSON.stringify(user))
  }

  removeUser() {
    localStorage.removeItem('user')
  }

  notEmpty() {
    const user = JSON.parse(localStorage.getItem('user'))
    const cond1 = user?.token !== undefined && user?.token.accessToken !== null
    const cond2 = user?.token !== undefined && user?.token.accessToken !== ''

    return user && cond1 && cond2
  }
}

export default new TokenService()
