import { List, Record } from 'immutable'
import * as StateUtils from '../../utils/stateUtils'

export const DefaultUser = Record({
  payload: {},
  meta: {
    ready: false,
    requested: false,
    error: false,
  },
})

export default class User extends DefaultUser {
  getUserRequest() {
    return StateUtils.createRequestedRecordState(new User())
  }

  getUserSuccess(payload) {
    try {
      if (!payload || payload.length === 0) {
      }
      const user = StateUtils.createSuccessRecordState(new User())
      return user.set('payload', payload.data)
    } catch (err) {
      console.log('error: ', err)
    }
  }

  getUserFailure(payload) {
    return StateUtils.createFailureRecordState(new User())
  }
}
