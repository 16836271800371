export default {
  'development-local-stag': {
    API_URL: 'http://localhost:3001',
    AUTH_URL: 'http://localhost:3001',
  },
  'development-stage-stag': {
    API_URL: 'https://advertisement-api.fly.dev',
    AUTH_URL: 'https://advertisement-api.fly.dev',
  },
}
